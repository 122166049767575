




















































































import { RouterNames } from "@/router/routernames";
import { incidenciaAllDto } from "@/shared/dtos/incidencias/incidenciaAllDto";
import { inventarioIncidenciasDto } from "@/shared/dtos/incidencias/inventarioincidenciasDto";
import { inventarioTareasDto } from "@/shared/dtos/tareas/inventarioTareasDto";
import { tareaAllDto } from "@/shared/dtos/tareas/tareaAllDto";
import incidenciaModule from "@/store/modules/incidencia-module";
import invetarioModule from "@/store/modules/invetario-module";
import tareaModule from "@/store/modules/tarea-module";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class ReadQrIventario extends Vue {
  public id_inventario: number = -1;
  created() {
    this.id_inventario = Number.parseInt(this.$route.params.id);
    invetarioModule.getinvetario(this.id_inventario);
    incidenciaModule.getincidencias_movil_id(this.id_inventario);
    tareaModule.gettareaes_movil_id(this.id_inventario);
  }
  get datasource() {
    return invetarioModule.invetario;
  }
  public get incidencias() {
    let datas: inventarioIncidenciasDto[] = [];
    console.log(incidenciaModule.incidencias_inventario);
    for (let i = 0; i < incidenciaModule.incidencias_inventario.length; i++) {
      const element = incidenciaModule.incidencias_inventario[i];
      let elementos: incidenciaAllDto[] = [];

      elementos = element.incidencias.filter(
        (k) => k.estado.estate_closed === false
      );

      if (elementos.length > 0) {
        element.incidencias = elementos;
        datas.push(element);
      }
    }

    if (this.id_inventario !== -1) {
      datas = datas.filter((x) => x.inventario.id);
    }

    if (datas.length > 0) {
      return datas[0].incidencias.length;
    } else {
      return incidenciaModule.incidencias_inventario;
    }
  }

  public get tareas() {
    /*return tareaModule.tareas_inventario.filter((x) =>
      x.tareas.filter((k) => k.estado.estate_closed === this.tareas_a_ver)
    );*/

    let datas: inventarioTareasDto[] = [];
    tareaModule.onGetTareaMovil(
      tareaModule.tareas_inventario.filter((x) => x.inventario.id)
    );
    for (let i = 0; i < tareaModule.tareas_inventario.length; i++) {
      const element = tareaModule.tareas_inventario[i];
      let elementos: tareaAllDto[] = [];

      elementos = element.tareas.filter(
        (k) =>
          k.estado.estate_closed === false &&
          k.estado.estate_supervised === false
      );

      if (elementos.length > 0) {
        element.tareas = elementos;
        datas.push(element);
      }
    }

    if (this.id_inventario !== -1) {
      datas = datas.filter((x) => x.inventario.id);
    }

    if (datas.length > 0) {
      return datas[0].tareas.length;
    } else {
      return 0;
    }
  }

  public open_ficha() {
    this.$router.push({
      name: RouterNames.inventario_formulario,
      params: { id: this.$route.params.id },
    });
  }

  public open_tareas() {
    this.$router.push({
      name: RouterNames.inventario_tareas,
      params: { id: this.$route.params.id },
    });
  }

  public open_incidencias() {
    this.$router.push({
      name: RouterNames.inventario_incidencias,
      params: { id: this.$route.params.id },
    });
  }
}
